/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';

body { background-color: white; }

.success-snackbar {
    background: rgb(0, 175, 0);
}
  
.failed-snackbar {
    background: rgb(255, 19, 19);
}

.header-scrolled {
    background-color: white;
}

.custom-dialog-container .mat-dialog-container {
    display: block;
    padding: 0px;
    border-radius: 4px;
    box-sizing: border-box;
    overflow: auto;
    outline: 0;
    width: 100%;
    height: 100%;
    min-height: inherit;
    max-height: inherit;
}
